import { useTranslation } from 'react-i18next';
import '../styles/styles.css'
import imgs from '../constants/images';
import { ThemeContext } from '../../logic/theme/themeProvider';
import { useContext, useEffect, useState } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';

export function Header() {
    
    const [authenticated, setAuthenticated] = useState(false);
    const [user, setUser] = useState(null);

    // language
    const { t, i18n } = useTranslation("global");
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    // theme
    const { theme, toggleTheme } = useContext(ThemeContext);
    const indexes = [
        'home','about',"services", 'team','project'
    ]
        
    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
            if (currentUser) {
            setAuthenticated(true);
            setUser(currentUser); // Set the user object
            } else {
            setAuthenticated(false);
            }
        });
        return () => unsubscribe();
    }, []);

    const handleLogout = async () => {
        const isConfirmed = window.confirm("Do you want to logout?");
        if (isConfirmed) {
          const auth = getAuth();
          try {
            await signOut(auth);
            window.location.href = '/';
          } catch (error) {
            console.error('Error logging out:', error);
            alert('Failed to log out. Please try again.');
          }
        }
    };
    
    return  <header id="home">
        <nav class="navbar mobile-sidenav attr-border navbar-sticky navbar-default validnavs navbar-fixed white  no-background">


        <div class="container d-flex justify-content-between align-items-center">            

            <div class="navbar-header">
                <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#navbar-menu">
                    <i class={`fa fa-bars ${theme === 'dark' && 'text-light'}`}></i>
                </button>
                <div>
                    <a class="navbar-brand fx-logo" href="#">
                        <img src={imgs.logoLight} class="logo logo-display" alt="Logo" />
                        <img src={imgs.logo} class="logo logo-scrolled" alt="Logo" />
                        <div>
                            <div type="button" class="navbar-toggles"  onClick={toggleTheme}>
                                {theme==="dark" ? <i class={`fa fa-x fa-sun ${theme === 'dark' && 'adark'}`}></i> : <i class={`fa fa-1x fa-moon ${theme === 'dark' && 'adark'}`}></i>}
                            </div>
                        </div>
                    </a>
                </div>
            </div>

            <div class="collapse navbar-collapse" id="navbar-menu">

                <Link to={"/"}>
                    <img src={imgs.logo} alt="Logo" />
                </Link>
                
                <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#navbar-menu">
                    <i class="fa fa-times"></i>
                </button>

                <ul class="nav navbar-nav navbar-right" data-in="fadeInDown" data-out="fadeOutUp">
                    
                {!authenticated && !user ? (
                    <>
                        {[
                            t('header.home'),
                            t('header.about'),
                            "Services",
                            t('header.team'),
                            t('header.project'),
                        ].map((e,i) => (
                            <li class="">
                                <a href={`#${indexes[i]}`} className={`"dropdown-toggle active   ${theme === 'dark' && 'adark'}`} data-toggle="dropdown" >{e}</a>
                            </li>
                        ))}

                        <li class="dropdown">
                            <a href="#s" class="dropdown-toggle active" data-toggle="dropdown" >{theme==="dark" ? <i class={`fa fa-2x fa-moon ${theme === 'dark' && 'adark'}`}></i> : <i class={`fa fa-2x fa-sun ${theme === 'dark' && 'adark'}`}></i>}</a>
                            <div class="dropdown-menu language">
                                <div>

                                    <btn class="lng">
                                        <div className="lng-item"  onClick={toggleTheme}>
                                            <label><i class="fa fa-moon fa-1x pr-5 mr-5"></i> Dark </label>
                                        </div>
                                        <div className="lng-item" onClick={toggleTheme}>
                                            <label> <i class="fa fa-sun fa-1x pr-5 mr-5"></i> Light </label>
                                        </div>
                                    </btn>
                                    
                                </div>
                            </div>
                        </li>

                        <li class="dropdown">
                            <a href="#s" class="dropdown-toggle active" data-toggle="dropdown" ><i class={`fa fa-2x fa-language ${theme === 'dark' && 'adark'}`}></i></a>
                            <div class="dropdown-menu language">
                                <div>
                                    <btn class="lng">
                                        <div className="lng-item"  onClick={() => changeLanguage('en')}>
                                            <div className="img">
                                                <img src={imgs.french_flag} class="logo logo-displays" alt="Logo" />
                                            </div>
                                            <label>{t('english')}</label>
                                        </div>
                                        <div className="lng-item"  onClick={() => changeLanguage('fr')}>
                                            <div className="img">
                                                <img src={imgs.french_flag} class="logo logo-displays" alt="Logo" />
                                            </div>
                                            <label>{t('french')}</label>
                                        </div>
                                    </btn>
                                    
                                </div>
                            </div>
                        </li>
                    </>
                ):<>
                    <li className="">
                        <Link to={'/'}>
                            <a href='#s' class={`${theme === "dark" ? "text-light":"text-dark" }`}> Home  </a>
                        </Link>
                    </li>
                    <li className="">
                        <Link to={'/dashboard/projects'}>
                            <a href='#s' class={`${theme === "dark" ? "text-light":"text-dark" }`}> Projects  </a>
                        </Link>
                    </li>
                    <li onClick={handleLogout} className="">
                        <a href='#s' > Logout  </a>
                    </li>
                </>}


                    <li>
                        <div class="attr-navs mx-2">
                            <ul>
                                {!authenticated && !user ? (
                                    <Link to={'/login'}>
                                        <span className="btn circle btn-theme-effect btn-sm px-5"> <i className="mr-5 fa fa-user"></i> {t('login')} </span>
                                    </Link>
                                ): <>
                                    <Link to={'/dashboard'}>
                                        <span className="btn circle btn-theme-effect btn-sm px-5"> <i className="mr-5 fa fa-user"></i> {t('Admin')} </span>
                                    </Link>
                                </>}
                            </ul>
                        </div>
                    </li>
                    
                </ul>
                
                
            </div>   
            <div class="overlay-screen"></div>
        </div>
    </nav>
</header>
}
export default Header;