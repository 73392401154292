import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { firestore, collection, getDocs, deleteDoc, doc } from './logic/firebase';
import { BarLoader, BeatLoader, PuffLoader } from 'react-spinners';

function ProjectList() {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, 'projects'));
        const projectsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setProjects(projectsData);
      } catch (error) {
        console.error('Error fetching projects:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  const handleDelete = async (projectId) => {
    if (window.confirm('Are you sure you want to delete this project?')) {
      try {
        await deleteDoc(doc(firestore, 'projects', projectId));
        setProjects(projects.filter(project => project.id !== projectId));
        alert('Project deleted successfully!');
      } catch (error) {
        console.error('Error deleting project:', error);
        alert('Failed to delete project. Please try again.');
      }
    }
  };



  return (
    <div className='container projects'>
      <div className="d-flex justify-content-space-between align-items-center mb-3">
        <div className="row head">
          <div className="col-lg-6 col-12">
            <h2 className='m-5'>Projects</h2>
          </div>
          <div className="col-lg-6 col-12">
            <Link to="/dashboard/project/new" className="btn btn-primary">Add New Project</Link>
          </div>
        </div>
      </div>

      <div className="row">
      
        {loading ? (
          <BarLoader  
          width="100%"
          size={40}
          color="blue"
          loading={true}
          speedMultiplier={1}
        />
        ):<></>}

        {projects.length >=1 ? (
          <>

            {projects.map((project) => (
              <div className="col-12 col-md-6 col-lg-4 mb-3 card-s" key={project.id}>
                <div className="cards">
                  <img src={project.imageUrl} className="card-img-top" alt={project.title} />
                  <div className="card-body">
                    <h3 className="card-title"><b> {project.title} </b></h3>
                    <p className="card-text">{project.description}</p>
                    <p className="card-text"><small className="text-muted">{project.time}</small></p>
                      {project.fileUrl !== "" ? (
                        <a className='mx-2 btn btn-success' href={project.fileUrl} download> Download Apk </a>
                      ) : project.url !== "" ? <>
                        <a className='mx-2 btn btn-success' href={project.url} download> Site web </a>
                      </> : <></>}
                      <a onClick={() => handleDelete(project.id)} className='mx-2 btn btn-danger' href="#s"> Delete </a>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) :<>
        </> }


      </div>
    </div>
  );
}

export default ProjectList;
