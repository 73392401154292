import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { firestore, storage, ref, uploadBytes, getDownloadURL, collection, addDoc } from '../admin/logic/firebase'; // Ensure correct path

function AddProject() {
  const [title, setTitle] = useState('');
  const [url, setUrl] = useState('');
  
  const [description, setDescription] = useState('');

  const [image, setImage] = useState();
  const [image2, setImage2] = useState();
  const [image3, setImage3] = useState();
  const [image4, setImage4] = useState(null);

  const [file, setFile] = useState(null);
  const [file2, setFile2] = useState(null);
  const [file3, setFile3] = useState(null);
  const [file4, setFile4] = useState(null);


  const [uploading, setUploading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!title || !description) {
      alert('Please fill out all fields and upload all files.');
      return;
    }

    if (!url && !file  && !file2 && !file3 && !file4) {
      alert("Veillez saisir l'url du project soit importer un fichier");
      return;
    }

    if (!file && !file2 && !file3 && !file4) {
      alert("Veillez importer aumoin un fichier setup");
      return;
    }

    setUploading(true);

    try {
      if (!(image instanceof File)) {
        throw new Error('Invalid file objects.');
      }

      const uploadFile = async (file, path) => {
        const fileRef = ref(storage, path);
        await uploadBytes(fileRef, file);
        return await getDownloadURL(fileRef);
      };

      const imageUrl =  image instanceof File ? await uploadFile(image, `images/${encodeURIComponent(image.name)}`) : null
      const imageUrl2 = image2 instanceof File ? await uploadFile(image2, `images/${encodeURIComponent(image2.name)}`) : null
      const imageUrl3 = image3 instanceof File ? await uploadFile(image3, `images/${encodeURIComponent(image3.name)}`) : null
      const imageUrl4 = image4 instanceof File ? await uploadFile(image4, `images/${encodeURIComponent(image4.name)}`) : null

      const android = file instanceof File ? await uploadFile(file, `files/${encodeURIComponent(file.name)}`) : null
      const windows = file2 instanceof File ? await uploadFile(file2, `files/${encodeURIComponent(file2.name)}`) : null
      const mac = file3 instanceof File ? await uploadFile(file3, `files/${encodeURIComponent(file3.name)}`) : null
      const ios = file4 instanceof File ? await uploadFile(file4, `files/${encodeURIComponent(file4.name)}`) : null

      // Save project data to Firestore
      await addDoc(collection(firestore, 'projects'), {
        title,
        description,
        url,
        imageUrl,
        imageUrl2,
        imageUrl3,
        imageUrl4,
        android,
        windows,
        mac,
        ios
      });

      alert('Project added successfully!');
      navigate('/dashboard/projects');

    } catch (error) {
      console.error('Error adding project:', error);
      alert('Failed to add project. Please try again.');
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className='container'>
      <h2>Add New Project</h2>
      <div className="col-lg-6 col-12">
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="mb-3">
            <label htmlFor="title" className="form-label">Title *</label>
            <input
              type="text"
              className="form-control"
              id="title"
              value={title}
              required
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="url" className="form-label">Url site web (optional)</label>
            <input
              type="text"
              className="form-control"
              id="url"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="description" className="form-label">Description *</label>
            <textarea
              className="form-control"
              id="description"
              rows="7"
              required
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
          </div>

          <br />
          <hr /><br />

          <div className="mb-3">
            <label htmlFor="image" className="form-label"> Image de couverture 1 * </label>
            <input
              type="file"
              className="form-control"
              id="image"
              required
              accept="image/*"
              onChange={(e) => setImage(e.target.files[0])}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="image2" className="form-label">Image de couverture 2 (Optional)</label>
            <input
              type="file"
              className="form-control"
              id="image2"
              accept="image/*"
              onChange={(e) => setImage2(e.target.files[0])}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="image3" className="form-label">Image de couverture 3 (Optional)</label>
            <input
              type="file"
              className="form-control"
              id="image3"
              accept="image/*"
              onChange={(e) => setImage3(e.target.files[0])}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="image4" className="form-label">Image de couverture 4 (optional)</label>
            <input
              type="file"
              className="form-control"
              id="image4"
              accept="image/*"
              onChange={(e) => setImage4(e.target.files[0])}
            />
          </div>

          <br />
          <hr /><br />

          {/* <div className="mb-3">
            <label htmlFor="file" className="form-label"> Type de fichier </label>
            <select onChange={(e) => setTypefile(e.target.value)}  className="form-control" required name="typeFile" id="typeFile">
              <option value=""> Choisir ... </option>
              <option value="windows"> Windows </option>
              <option value="android"> Android </option>
              <option value="ios"> IOS </option>
              <option value="mac"> MacOs </option>
              <option disabled value="linux"> Linux </option>
            </select>
          </div> */}

          <div className="mb-3">
            <label htmlFor="file" className="form-label">Importer le fichier android (optional) </label>
            <input
              type="file"
              className="form-control"
              id="file"
              
              onChange={(e) => setFile(e.target.files[0])}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="file2" className="form-label">Importer setup windows (Optional) </label>
            <input
              type="file"
              className="form-control"
              id="file2"
              
              onChange={(e) => setFile2(e.target.files[0])}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="file3" className="form-label">Importer setup MacBook (optional)</label>
            <input
              type="file"
              className="form-control"
              id="file3"
              
              onChange={(e) => setFile3(e.target.files[0])}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="file4" className="form-label">Importer IOS (Optional) </label>
            <input
              type="file"
              className="form-control"
              id="file4"
              
              onChange={(e) => setFile4(e.target.files[0])}
            />
          </div>

          <br />
          <hr /><br />

          <button type="submit" className="btn btn-primary" disabled={uploading}>
            {uploading ? 'Uploading...' : 'Add Project'}
          </button>

          <br />
          <br />
          <br />
          <br />
        </form>
      </div>
    </div>
  );
}

export default AddProject;
