import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Header } from '../header';
import { useLocation } from 'react-router-dom';
import ProjectList from './project';
import AddProject from './new';
import './style.css'; 
import { getAuth, signOut } from 'firebase/auth';

function Dashboard() {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setAuthenticated(true);
      } else {
        setAuthenticated(false);
        navigate('/login');
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleLogout = async () => {
    const isConfirmed = window.confirm("Do you want to logout?");
    if (isConfirmed) {
      const auth = getAuth();
      try {
        await signOut(auth);
        navigate('/login');
      } catch (error) {
        console.error('Error logging out:', error);
        alert('Failed to log out. Please try again.');
      }
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!authenticated) {
    return null;
  }

  return (
    <>
      <div className="bg-primary">
        <Header />
      </div>

      <div className="bg-light p-0 d-flex cnt-project">
        <Link to={"/dashboard/projects"}>
          <button type='submit' className="btn-sm"> Projets </button>
        </Link>
        <div className='col-1'></div>
        <button onClick={handleLogout} type='submit' className="bg-danger"> Deconnexion </button>
        <hr />
      </div>

      <div className="container-fluid">
        {location.pathname === '/dashboard/projects' ? (
          <ProjectList />
        ) : null}

        {location.pathname === '/dashboard/project/new' ? (
          <AddProject />
        ) : null}
      </div>
    </>
  );
}

export default Dashboard;
