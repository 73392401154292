import React from 'react';
import imgs from '../constants/images';
import { useTranslation } from 'react-i18next';
import { useServices } from '../../hooks/useServices';

const OurServices = ({ theme }) => {

    const { t } = useTranslation("global");
    const services = useServices();

    return (
        <div id="services" className={`team-area default-padding bottom-less ourservices ${theme === 'dark' ? 'bg-transparent' : 'bgdk'}`}>
            <div className="bg_bk"></div>
            <div className="container">
                <div className="row align-center">
                    <div className="collaboration-style-one col-lg-8 pr-80 pr-md-15 pl-xs-15">
                        <h4 className="sub-heading tag">{t("service.label")}</h4>
                        <h2 className="heading">{t("service.title")}</h2>
                        <p>{t("service.subtitle")}</p>
                        <div className="services">
                            {services.map(element => (
                                <div  key={element.id} className={`subcontainer ${theme === 'dark' ? 'bg-dark' : 'bg-light'}`}>
                                    {element.title}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="collaboration-style-one col-lg-4">
                        <div className="thumb">
                            <img src={imgs.app_img_22x} alt="alt" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OurServices;
