import { useTranslation } from 'react-i18next';
import imgs from '../components/constants/images';

export const useServices = () => {
    const { t } = useTranslation("global");

    return [
        {
            id: 1,
            title: t('service.s1'),
            icon: imgs.favicon
        },
        {
            id: 2,
            title: t('service.s2'),
            icon: imgs.favicon
        },
        {
            id: 3,
            title: t('service.s3'),
            icon: imgs.favicon
        },
        {
            id: 4,
            title: t('service.s4'),
            icon: imgs.favicon
        },
        {
            id: 5,
            title: t('service.s5'),
            icon: imgs.favicon
        },
        {
            id: 6,
            title: t('service.s6'),
            icon: imgs.favicon
        },
    ];
};