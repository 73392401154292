import React, { useContext, useEffect, useState } from 'react'
import Header from './header'
import Footer from './footer'
import { ThemeContext } from '../../logic/theme/themeProvider'
import { Link, useParams } from 'react-router-dom';
import { firestore, doc, getDoc } from './admin/logic/firebase'; // Adjust the path as necessary
import { useTranslation } from 'react-i18next';
import Process from './process';
import imgs from '../constants/images';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import Button from 'react-bootstrap/Button';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { ButtonGroup } from 'react-bootstrap';

function Project() {
  const { t } = useTranslation("global");

  const { theme } = useContext(ThemeContext);
  const { id } = useParams();
  const [menu, setShowMenu] = useState(false)

  const [productData, setProductData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const docRef = doc(firestore, 'projects', id); // Adjust 'products' to your collection name
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          console.log(docSnap.data());
          
          setProductData(docSnap.data());
          return 
        } else {
          console.log('No such document!');
          setProductData({});
        }
      } catch (error) {
        console.error('Error getting document:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProductData();
  }, [id]);

  if (loading) return <div>Loading...</div>;

  if (!productData || Object.keys(productData).length === 0) {
    return <div>No product data available.</div>;
  }



  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  
  return (
    <>
      <Header />
        <div className="banner-area text-light bg-gradient banner-style-five text-default custom_banner single-pg">
          
          <div className="animated-wave">
              <svg className="waves" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                  <defs>
                      <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                  </defs>
                  <g className="parallax">
                      <use href="#gentle-wave" x="48" y="0" fill={`${theme === "light" ? "#ffffffb3" : "#91acdfb3"}`} />
                      <use href="#gentle-wave" x="48" y="3" fill={`${theme === "light" ? "#ffffff80" : "#426dc980"}`} />
                      <use href="#gentle-wave" x="48" y="5" fill={`${theme === "light" ? "#ffffff4d" : "#061c464d"}`} />
                      <use href="#gentle-wave" x="48" y="7" fill={`${theme === "light" ? "#fff" : "#040f244d"}`} />
                  </g>
              </svg>
          </div>

          <div className="bg_bk"></div>
            <div className="container">
              <div>
                <Carousel
                  additionalTransfrom={0}
                  arrows
                  autoPlaySpeed={3000}
                  centerMode={false}
                  className="imgsProjects"
                  containerClass="container"
                  dotListClass=""
                  draggable
                  focusOnSelect={false}
                  infinite
                  itemClass=""
                  keyBoardControl
                  minimumTouchDrag={80}
                  pauseOnHover
                  renderArrowsWhenDisabled={false}
                  renderButtonGroupOutside={false}
                  renderDotsOutside={false}
                  responsive={{
                    desktop: {
                      breakpoint: {
                        max: 3000,
                        min: 1024
                      },
                      items: 1
                    },
                    mobile: {
                      breakpoint: {
                        max: 464,
                        min: 0
                      },
                      items: 1
                    },
                    tablet: {
                      breakpoint: {
                        max: 1024,
                        min: 464
                      },
                      items: 1
                    }
                  }}
                  rewind={false}
                  rewindWithAnimation={false}
                  rtl={false}
                  shouldResetAutoplay
                  showDots
                  sliderClass=""
                  slidesToSlide={1}
                  swipeable
                  >
                    {productData.imageUrl && productData.imageUrl !== "" ? (
                      <img class="imgProject d-block w-100" src={productData.imageUrl} alt={productData.title} />
                    ): null}

                    {productData.imageUrl1 && productData.imageUrl1 !== "" ? (
                      <img class="imgProject d-block w-100" src={productData.imageUrl1} alt={productData.title} />
                    ):null}

                    {productData.imageUrl2 && productData.imageUrl2 !== "" ? (
                      <img class="imgProject d-block w-100" src={productData.imageUrl2} alt={productData.title} />
                    ):null}

                    {productData.imageUrl3 && productData.imageUrl3 !== "" ? (
                      <img class="imgProject d-block w-100" src={productData.imageUrl3} alt={productData.title} />
                    ):null}

                </Carousel>
              </div>
              <div>
                
                {productData.android || productData.windows || productData.ios || productData.mac ? (
                  <div className='col-12'>
                    
                    <a href="#s" className='btn btn-warning col-lg-4 col-md-6 col-12 py-3' onClick={() => setShowMenu(!menu)}> <b className="text-dark">{t('download')}<i className="fa fa-download mx-3"></i></b> </a>
                    
                    <div className='menus'>
                      {menu ? <>
                        
                        <div className="menu-item  fade-in">
                          {productData.android ? <>
                              <div className='m-2 pb-4'>
                                <a className="link" href={productData.android} download eventKey="1">
                                  <img src={imgs.android} alt="android" className="img" /> Android
                                </a>
                              </div>
                            </> : <></>}

                            {productData.windows ? <>
                              <div className='m-2 pb-4'>
                                <a className="link" href={productData.windows} download eventKey="1">
                                  <img src={imgs.win} alt="win" className="img" /> windows
                                </a>
                              </div>
                            </> : <></>}

                            {productData.ios ? <>
                              <div className='m-2 pb-4'>
                                <a className="link" href={productData.ios} download eventKey="1">
                                  <img src={imgs.ios} alt="ios" className="img" /> Iphone
                                </a>
                              </div>
                            </> : <></>}

                            {productData.mac ? <>
                              <div className='m-2 pb-4'>
                                <a className="link" href={productData.mac} download eventKey="1">
                                  <img src={imgs.mac} alt="macBook" className="img" /> Mac Book
                                </a>
                              </div>
                            </> : <></>}
                        </div>

                      </>: <></>}
                    </div>

                  </div>
                
                ) : <></>}

                {!productData.url !== "" ? <div className='col-12'>
                  <a target="_blank" rel="noreferrer" className='btn btn-warning col-lg-4 col-md-6 col-12 py-3' href={productData.url}> <b className="text-dark"> Site web <i className="fa fa-link mx-3"></i></b>  </a>
                </div> : <></>}

              </div>

                
            </div>
          </div>

        <div className="container py-5">
          <h1><b> {productData.title || 'Product Name'} </b></h1> 
          <p>{productData.description || 'No description available.'}</p>
        </div>

        <Process theme={theme} />

      <Footer theme={theme} />
    </>
  )
}

export default Project