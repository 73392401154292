import React from 'react'
import { useTranslation } from 'react-i18next';
import Carousel from '../constants/modelCarousel';
import imgs from '../constants/images';

function Team({theme}) {

    const { t } = useTranslation("global");

    const team = [
        {
            "id": 1,
            "name": 'Muyisa Winner',
            "fonction": t("team.fonction1"),
            "media": {
                "fb": "#link",
                "linkedin": "#link",
            },
            "photo": imgs.winner
        },
        {
            "id": 2,
            "name": 'Bismarck Kizito',
            "fonction": t("team.fonction2"),
            "media": {
                "fb": "#link",
                "linkedin": "#link",
            },
            "photo": imgs.bismark
        },
        {
            "id": 3,
            "name": 'Richard Muhyana',
            "fonction": t("team.fonction3"),
            "media": {
                "fb": "#link",
                "linkedin": "#link",
            },
            "photo": imgs.richard
        },
        {
            "id": 4,
            "name": 'Daniel Muyisa',
            "fonction": t("team.fonction5"),
            "media": {
                "fb": "#link",
                "linkedin": "#link",
            },
            "photo": imgs.daniel
        },
        {
            "id": 5,
            "name": 'KAMI BETHEL',
            "fonction": t("team.fonction7"),
            "media": {
                "fb": "#link",
                "linkedin": "#link",
            },
            "photo": imgs.elbethel
        }, {
            "id": 6,
            "name": 'ANDERSON',
            "fonction": t("team.fonction7"),
            "media": {
                "fb": "#link",
                "linkedin": "#link",
            },
            "photo": imgs.favicon
        }, {
            "id": 7,
            "name": 'EMERY DESIGN',
            "fonction": t("team.fonction8"),
            "media": {
                "fb": "#link",
                "linkedin": "#link",
            },
            "photo": imgs.favicon
        }
    ];

    const ModelCard = ({ name, fonction, media, photo }) => (
        <div className={`single-item mx-2 card_team ${theme === 'dark' ? 'bg-light' : 'bgdark'}`}>
            <div className="item text-center">
                <div className="thumb">
                    <div className="img">
                        <img src={photo} alt={name} />
                    </div>
                </div>
                <div className="devider"></div>
                    <div className="info">
                    <h4><b> {name} </b></h4>
                    <p>{fonction}</p>
                    <div className="media">
                        <div>
                            <i className="fab fa-linkedin"></i>
                            <i className="fa fa-envelope"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

  return (
    <div id="team" class={`team-area default-padding bottom-less custom_team  ${theme === 'dark' ? 'bg-light' : 'bgdark'}`}>
        <div className="bg_bk"></div>

        <div className="col-lg-8 offset-lg-2">
            <div className="site-heading text-center">
                <h2>{t("team.title")}</h2>
                <div className="devider"></div>
                <p>
                    {t('team.subtitle')}
                </p>
            </div>
        </div>

        <div className="container">
            <div className="team-items">

                <Carousel>
                    {team.map(member => (
                    <ModelCard 
                        key={member.id}
                        name={member.name}
                        fonction={member.fonction}
                        media={member.media}
                        photo={member.photo}
                    />
                    ))}
                </Carousel>

            </div>
        </div>
    </div>
  )
}

export default Team