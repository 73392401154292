import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import imgs from '../../constants/images';
import { PuffLoader } from 'react-spinners';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../admin/logic/firebase';
import Header from '../header';
import Footer from '../footer';

function Login({ theme }) {
  const { t } = useTranslation("global");
  const navigate = useNavigate();

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [animationKey, setAnimationKey] = useState(0);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!email || !password) {
      setError('Please fill in all fields');
      setShowError(true);
      setLoading(false);
      return;
    }

    try {
      await signInWithEmailAndPassword(auth, email, password);
      setError('Connexion success');

      setShowError(false);
      navigate('/dashboard');
    } catch (err) {
      setError('Invalid email or password');
      setShowError(true);
    } finally {
      setLoading(false);
    }
  };

  const close = () => {
    setShowError(false);
  }

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        navigate('/dashboard');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  return (
    <>

        <div className="container logincontainer  text-center py-4">
          
          <Link to={"/"}>
            <img width={100} height={100} src={imgs.favicon} alt="logo" />
          </Link>

          <h3 className='tag'> NDALOS SOFTWARE </h3><br />

          <div className="banner-areass text-light bg-gradient banner-style-five text-default custom_banners">
            <div className="container">

                <div className="row align-center">

                  <div className="col-12 col-lg-6 info">

                    <form className='form' onSubmit={handleSubmit}>
                      
                      <h4 className="" data-wow-defaul="300ms">{t('Authentification')}</h4>

                      <div>

                        {showError && (
                          <div className="alert alert-danger">
                            <i onClick={close} className="fa fa-cancel bg-white"> Fermer</i>
                            <p className='text-dark'>{error}</p>
                          </div>
                        )}

                        <div className="form-group mt-1 pt-1">
                          <input
                            onChange={(e) => setEmail(e.target.value)}
                            type="email"
                            className="form-control"
                            name='email'
                            id='email'
                            placeholder='Email'
                            required
                          />
                        </div>
                        <br />

                        <div className="form-group">
                          <input
                            onChange={(e) => setPassword(e.target.value)}
                            type="password"
                            className="form-control"
                            name='password'
                            id='password'
                            placeholder='Password'
                            required
                          />
                        </div>
                        
                        <br />

                      </div>

                      <button disabled={loading} type='submit' className="button btn circle btn-theme-effect btn-sm ">
                        {loading ? (
                          <PuffLoader
                            size={60}
                            color="#000000"
                            loading={true}
                            speedMultiplier={1}
                          />
                        ) : (
                          <span>{t("Valider")}</span>
                        )}
                      </button>

                    </form>
                    
                  </div>

                </div>

              </div>
              
          </div>

          <div className="py-5">
            <h5> Ndalos-soft</h5>
          </div>

        </div>


    </>
  )
}

export default Login;
