import React from 'react'
import { useTranslation } from 'react-i18next';
import imgs from '../constants/images';

function About({theme}) {
  
    const { t } = useTranslation("global");

  return (
    <div id="about2" className={`collaborationa-area default-padding custom_about  ${theme === 'dark' ? 'bg-light' : ''}`}>
        
        <div className="bg_bk"></div>
        <div className="container">
            <div className="row align-center">
                
                <div className="collaboration-style-one col-12 col-md-6 col-lg-5">
                    <div className="thumb">
                        <img src={imgs.app_img_7} alt="alt" />
                    </div>
                </div>

                <div className="collaboration-style-one col-12 col-md-6 col-lg-7 pl-80 pl-md-15 pl-xs-15">
                    <h4 className="sub-heading tag">{t("about.label")}</h4>
                    <h2 className="heading">{t("about.title")}</h2>
                    <p>
                    {t("about.subtitle")}
                    </p>
                    <ul>
                        <li>
                            <div className="icon">
                                <i className="fas fa-user-cog abouticon"></i>
                            </div>
                            <div className="info">
                                <h5>{t('about.tools')}</h5>
                                <p>
                                {t('about.tools_resume')}
                                </p>
                            </div>
                        </li>
                        <li>
                            <div className="icon">
                                <i className="fas fa-undo abouticon"></i>
                            </div>
                            <div className="info">
                                <h5>{t("about.integration")}</h5>
                                <p>
                                    {t("about.integration_res")} 
                                 </p>
                            </div>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
    </div>
  )
}

export default About