import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import imgs from '../constants/images';

function Bannier({theme}) {
  const { t } = useTranslation("global");
  
  const images = [
    imgs.app_img_1,
    imgs.app_img_3,
    imgs.app_img_33,
    imgs.app_img_4,
    imgs.app_img_5,
    imgs.app_img_6,
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [animationKey, setAnimationKey] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(prevIndex => {
        const nextIndex = (prevIndex + 1) % images.length;
        setAnimationKey(prevKey => prevKey + 1); // Increment key to force re-render
        return nextIndex;
      });
    }, 15000); // Change image every 5 seconds

    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  return (
    <div className="banner-area text-light bg-gradient banner-style-five text-default custom_banner">

        <div className="animated-wave">
            <svg className="waves" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                <defs>
                    <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                </defs>
                <g className="parallax">
                    <use href="#gentle-wave" x="48" y="0" fill={`${theme === "light" ? "#ffffffb3" : "#91acdfb3"}`} />
                    <use href="#gentle-wave" x="48" y="3" fill={`${theme === "light" ? "#ffffff80" : "#426dc980"}`} />
                    <use href="#gentle-wave" x="48" y="5" fill={`${theme === "light" ? "#ffffff4d" : "#061c464d"}`} />
                    <use href="#gentle-wave" x="48" y="7" fill={`${theme === "light" ? "#fff" : "#040f244d"}`} />
                </g>
            </svg>

        </div>

        <div className="bg_bk"></div>

        <div className="container ">
            <div className="double-items">
                <div className="row">

                    <div className="col-lg-6 info">
                        <h3 className='tag'> #Next Development Advaced Logic Software </h3>
                        
                        <h6 className={`title wow fadeInLeft ${theme==="dark"?"text-white":"text-light" }`} data-wow-defaul="300ms">{t('banner.title')}</h6>
                        
                        <p className={`subtitle wow fadeInLeft ${theme==="dark"?"text-white":"text-light" }`} data-wow-delay="500ms">
                            {t('banner.body')}
                        </p>

                        <div className="row">
                            {/* <div className="col-12 col-lg-12">
                                <div className="button">
                                    <a href="https://www.youtube.com/watch?v=owhuBrGIOsE" className={`relative popup-youtube video-btn  ${theme==="dark"?"text-white":"text-light" }`}><i className="fas fa-play"></i>{t("banner.action")}</a>
                                </div>
                            </div> */}
                            <div className="col-12 col-lg-12">
                                <div className="button">
                                    <a href="mailto:contact@ndalog.com" className="btn circle btn-theme-effect btn-sm">{t("banner.contact")}</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 thumb wow fadeInRight image-slider" data-wow-delay="900ms">
                        <img
                            src={images[currentImageIndex]}
                            alt="Slideshow"
                            key={animationKey} // Change key to trigger re-render
                            className="fade-in"
                        />
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
  )
}

export default Bannier