const imgs = {
    favicon: "/assets/img/favicon.png",
    logo: "/assets/img/logo.png",
    logoLight: "/assets/img/logo-light.png",
    french_flag: '/assets/img/flags/france.png',
    app_img_1: '/assets/img/icon/app/1.png',
    app_img_2: '/assets/img/icon/app/2.png',
    app_img_3: '/assets/img/icon/app/3.png',
    app_img_22: '/assets/bg/2.png',
    app_img_33: '/assets/img/icon/app/3.svg',
    app_img_4: '/assets/img/icon/app/4.png',
    app_img_5: '/assets/img/icon/app/5.png',
    app_img_6: '/assets/img/icon/app/6.png',
    app_img_7: '/assets/img/icon/app/7.png',
    app_img_12: '/assets/img/icon/app/12.png',
    app_img_22x: '/assets/img/icon/app/22.png',

    partenaire_img_1: "/assets/img/logo/1.png",
    partenaire_img_2: "/assets/img/logo/2.png",
    partenaire_img_3: "/assets/img/logo/3.png",
    partenaire_img_4: "/assets/img/logo/4.png",
    partenaire_img_5: "/assets/img/logo/5.png",

    bismark: "/assets/img/team/bismark2.PNG",
    richard: "/assets/img/team/richard.png",
    winner: "/assets/img/team/winner.png",
    elbethel: "/assets/img/team/elbethel.PNG",
    daniel: "/assets/img/team/daniel.png",

    part1: "/assets/img/parteners/1.png",
    part2: "/assets/img/parteners/2.png",
    part3: "/assets/img/parteners/3.png",
    part4: "/assets/img/parteners/4.png",
    part5: "/assets/img/parteners/5.png",

    ndalos: "/assets/img/projects/ndalos.png",
    hotel: "/assets/img/projects/hotel.jpg",

    android: "/assets/img/sys/android.png",
    ios: "/assets/img/sys/ios.jfif",
    mac: "/assets/img/sys/mac.jpg",
    win: "/assets/img/sys/win.png"

    
};

export default imgs;