import { initializeApp } from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';
import { getFirestore, collection, addDoc, getDocs, deleteDoc, doc, getDoc } from 'firebase/firestore';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyB01kIDsqPE8zc8UkCjmwqFOMMZ36kCkR4",
    authDomain: "ndalos-d3cf4.firebaseapp.com",
    projectId: "ndalos-d3cf4",
    storageBucket: "ndalos-d3cf4.appspot.com",
    messagingSenderId: "228352114607",
    appId: "1:228352114607:web:efc7f965ce61fedaeeb95a"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get a reference to the storage service
const storage = getStorage(app);

// Get a reference to the Firestore service
const firestore = getFirestore(app);

const auth = getAuth(app);

export { storage, firestore, ref, uploadBytes, getDownloadURL, auth, signInWithEmailAndPassword, collection, addDoc,getDocs, getDoc, deleteDoc, doc };
