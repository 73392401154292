import React from 'react'
import { useTranslation } from 'react-i18next';
import { useServices } from '../../hooks/useServices';
import imgs from '../constants/images';

function Footer({theme}) {
    const { t } = useTranslation("global");
    const services = useServices();

    return (
    <footer class={`footer text-light  ${theme === 'dark' ? 'bg-dark' : 'bg-light'}`}>
        <div className="container">
            <div className="f-items default-padding">
                <div className="row">
                    <div className="col-lg-4 col-md-6 item">
                        <div className="f-item about">
                            <img src={imgs.logoLight} alt="Logo" />
                            <p>
                                {t('footer.description')}
                            </p>
                            <form action="#" onSubmit={(e) => {
                                alert("Enregistrement succès; Merci pour votre confience")
                                }}>
                                <input required type="email" placeholder="Your Email" className="form-control" name="email" />
                                <button type="submit"> <i className="arrow_right"></i></button>  
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6 item">
                        <div className="f-item link">
                            <h4 className="widget-title">{t('footer.rapidlink')}</h4>
                            <ul>
                                <li>
                                    <a href="#home"><i className="fas fa-angle-right"></i>{t('header.home')}</a>
                                </li>
                                <li>
                                    <a href="#faith"><i className="fas fa-angle-right"></i>{t('header.faith')}</a>
                                </li>
                                <li>
                                    <a href="#about"><i className="fas fa-angle-right"></i>{t('header.about')}</a>
                                </li>
                                <li>
                                    <a href="#process"><i className="fas fa-angle-right"></i>{t('header.process')}</a>
                                </li>
                                
                                <li>
                                    <a href="#team"><i className="fas fa-angle-right"></i>{t('header.team')}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 item">
                        <div className="f-item link">
                            <h4 className="widget-title">{t('footer.ourservices')}</h4>
                            <ul>
                                {services.map((e, index) => (
                                    <li key={index}>
                                        <a href="#services"><i className="fas fa-angle-right"></i> {e.title}</a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 item ">
                        <div className="f-item contact-widget">
                            <h4 className="widget-title">{t('footer.ourcontacts')}</h4>
                            <div className="address">
                                <ul>
                                    <li>
                                        <div className="icon">
                                            <i className="fas fa-home"></i>
                                        </div>
                                        <div className="content">
                                            <strong>{t('footer.adress')}:</strong>
                                            {t('footer.localadr')}
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <i className="fas fa-envelope"></i>
                                        </div>
                                        <div className="content">
                                            <strong>Email:</strong>
                                            <a href="mailto:ndalosoft@gmail.com">ndalosoft@gmail.com</a>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <i className="fas fa-phone"></i>
                                        </div>
                                        <div className="content">
                                            <strong>Phone:</strong>
                                            <a href="tel:+243 992 910 692">+243 992 910 692</a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class={`footer-bottom ${theme === 'dark' ? 'bg-light' : 'bg-dark'}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <p>&copy; {t('footer.copyright')}</p>
                    </div>
                    {/* <div className="col-lg-6 text-end link">
                        <ul>
                            <li>
                                <a href="#s">Terms</a>
                            </li>
                            <li>
                                <a href="#s">Privacy</a>
                            </li>
                            <li>
                                <a href="#s">Support</a>
                            </li>
                        </ul>
                    </div> */}
                </div>
            </div>
        </div>
    </footer>
  )
}

export default Footer