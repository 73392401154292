import React from 'react'
import { useTranslation } from 'react-i18next';
import imgs from '../constants/images';

function Parteners() {

    const { t } = useTranslation("global");

    const partenaires = [
        imgs.part1,
        imgs.part2,
        imgs.part3,
        imgs.part4,
        imgs.part5
    ];

    const ModelCard = ({ name, fonction, photo }) => (
        <div className="single-item mx-2 card_team">
            <div className="item text-center">
                <div className="thumb">
                    <div className="img">
                        <img src={photo} alt={name} />
                    </div>
                </div>
                <div className="devider"></div>
                    <div className="info">
                    <h4>{name}</h4>
                    <p>{fonction}</p>
                    <div className="media">
                        <div>
                            <i className="fab fa-linkedin"></i>
                            <i className="fa fa-envelope"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

  return (
    <div id="team" className="team-area default-padding bottom-less partenaires">
        <div className="bg_bk"></div>

        <div className="col-lg-8 offset-lg-2">
            <div className="site-heading text-center">
                <h2>{t("parteners.title")}</h2>
                <div className="devider"></div>
                <p>
                    {t('parteners.subtitle')}
                </p>
            </div>
        </div>

        <div className="container">
            <div className="team-items">
                <div className="row">
                    {partenaires.map((e,i) => (
                        <div key={i} className="col-4 m-auto">
                            <div className="item-tartenaire">
                                <img src={e} alt="Partner" />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>

    </div>
  )
}

export default Parteners