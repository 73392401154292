import React, { useContext } from 'react'
import Header from './screens/header'
import Bannier from './screens/bannier'
import Features from './screens/features'
import About from './screens/about'
import OurServices from './screens/ourservices'
import Team from './screens/team'
import Parteners from './screens/parteners'
import Projects from './screens/projects'
import Footer from './screens/footer'
import { ThemeContext } from '../logic/theme/themeProvider'

function Home() {
  
    const { theme } = useContext(ThemeContext);
  
  return (
    <>
        <Header />
          <Bannier theme={theme} />
          <Features  />
          <About theme={theme}/>
          <OurServices theme={theme} />
          <Team theme={theme} />
          <Parteners />
          <Projects theme={theme} />
        <Footer theme={theme} />
    </>
  )
}

export default Home