import React from 'react'
import imgs from '../constants/images'

function Process({theme}) {
  return (
    <div id="process" className="work-process-area bg-lighs text-dark default-padding custom_process">
    <div className="container">
        <div className="row head-process-section">
            <div className="col-12 col-lg-6">
                <div className="site-heading text-left">
                    <h4 className="tag">Processus de travail</h4>
                    <h2>Comment ca marche ?</h2>
                    <div className="devider bg-secondary"></div>
                </div>
            </div>
            <div className="col-12 col-lg-6 actions">
                <div className="row nav nav-tabs  work-process-tab-navss it-process" id="nav-tab" role="tablist">
                    <div className={`col-4 nav-link px-4 active bg-light`} id="nav-id-1" data-bs-toggle="tab" data-bs-target="#tab1" type="button" role="tab" aria-controls="tab1" aria-selected="true">
                        <h4>Optimization</h4>
                    </div>
                    <div className={`col-4 nav-link px-4 bg-light`} id="nav-id-2" data-bs-toggle="tab" data-bs-target="#tab2" type="button" role="tab" aria-controls="tab2" aria-selected="false">
                        <h4>Integration</h4>
                    </div>
                    <div className={`col-4 nav-link px-4 bg-light`} id="nav-id-3" data-bs-toggle="tab" data-bs-target="#tab3" type="button" role="tab" aria-controls="tab3" aria-selected="false">
                        <h4>Performance</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                <div className="tab-content work-process-tab-content" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="nav-id-1">
                        <div className="row align-center">
                            
                            <div className="col-lg-6 p-0 m-0">
                                <div className="thumbs">
                                    <img src={imgs.app_img_12} alt="Optimazed img-vector" />
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className={`info ${theme === "dark" ? "bgbk" : "bglight"} `}>
                                    <h2>Optimisation mathématique</h2>
                                    <p>
                                        Tirez parti des techniques d'optimisation mathématique pour résoudre des problèmes 
                                        complexes et améliorer la performance de vos opérations. Nos solutions 
                                        vous permettent de modéliser et d'optimiser divers scénarios, 
                                        en maximisant l'efficacité tout en minimisant les coûts. 
                                        Que ce soit pour l'allocation des ressources, la planification 
                                        des horaires ou la gestion de la chaîne logistique, nous vous aidons 
                                        à atteindre vos objectifs avec précision et rapidité.
                                    </p>
                                    <ul>
                                        <li>
                                            <h3><i className="fa fa-check-square"></i> Utilisation incroyablement simple</h3>
                                            <p>
                                                Profitez d'une interface intuitive et facile à utiliser, 
                                                conçue pour vous permettre de démarrer rapidement et sans effort. 
                                                Nos outils sont créés avec une simplicité d'utilisation en tête, 
                                                vous permettant de vous concentrer sur ce qui compte vraiment. 
                                                Que vous soyez novice ou expert, vous pouvez naviguer facilement et tirer 
                                                le meilleur parti de nos solutions sans courbe d'apprentissage compliquée.

                                            </p>
                                        </li>
                                        <li>
                                            <h3><i className="fa fa-check-square"></i> Interface utilisateur flexible</h3>
                                            <p>
                                                Adaptez l'interface utilisateur à vos besoins spécifiques grâce à notre design 
                                                flexible et personnalisable. Que vous préfériez une interface 
                                                épurée ou riche en fonctionnalités, nos options de configuration 
                                                vous permettent de créer un environnement de travail qui répond parfaitement 
                                                à vos attentes. Cette flexibilité garantit une expérience utilisateur optimale, 
                                                quelle que soit la complexité de vos tâches.

                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="nav-id-2">
                        <div className="row align-center">
                            <div className="col-lg-6 pr-80 pr-md-15 pr-xs-15">
                                <div className="thumb">
                                    <img src={imgs.app_img_22x} alt="Performance img-vector" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className={`info ${theme === "dark" ? "bgbk" : "bglight"} `}>
                                    <h2>Intégration Technique</h2>
                                    <p>
                                        Assurez une intégration technique fluide avec des systèmes existants. 
                                        Nous fournissons des solutions robustes et adaptées pour connecter 
                                        votre site web avec divers outils et plateformes, garantissant une 
                                        performance optimale et une gestion efficace des données.
                                    </p>
                                    <ul>
                                        <li>
                                            <h3><i className="fa fa-check-square"></i> Utilisation Étonnamment Simple</h3>
                                            <p>
                                                Notre plateforme est conçue pour être incroyablement facile à utiliser. 
                                                Avec une interface intuitive et des fonctionnalités simples, vous pouvez 
                                                gérer votre contenu et vos opérations en quelques clics, sans nécessiter 
                                                de compétences techniques approfondies.

                                             </p>
                                        </li>
                                        <li>
                                            <h3> <i className="fa fa-check-square"></i> Interface Utilisateur Flexible</h3>
                                            <p>
                                                Profitez d'une interface utilisateur adaptable à vos besoins spécifiques. 
                                                Notre design flexible vous permet de personnaliser l'apparence et les fonctionnalités 
                                                de votre site web, assurant une expérience utilisateur fluide et agréable, 
                                                quel que soit l'appareil utilisé.

                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="tab-pane fade" id="tab3" role="tabpanel" aria-labelledby="nav-id-3">
                        <div className="row align-center">
                            <div className="col-lg-6 pr-80 pr-md-15 pr-xs-15">
                                <div className="thumb">
                                    <img src={imgs.app_img_7} alt="Performance img-vector" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className={`info ${theme === "dark" ? "bgbk" : "bglight"} `}>
                                    <h2>Super Performance</h2>
                                    <p>
                                        Notre solution est conçue pour offrir une performance exceptionnelle 
                                        dans toutes les situations. Nous garantissons une expérience fluide 
                                        et rapide, même dans les conditions les plus exigeantes, grâce à une 
                                        architecture optimisée et des technologies avancées.

                                    </p>
                                    <ul>
                                        <li>
                                            <h3><i className="fa fa-check-square"></i> Utilisation Étonnamment Simple</h3>
                                            <p>
                                                Notre plateforme est conçue pour être d'une simplicité 
                                                remarquable. Vous trouverez que la gestion de vos opérations
                                                 est directe et sans complications, vous permettant de vous concentrer sur 
                                                 ce qui est important sans vous perdre dans des détails techniques.

                                            </p>
                                        </li>
                                        <li>
                                            <h3> <i className="fa fa-check-square"></i> Interface Utilisateur Flexible</h3>
                                            <p>
                                                Avec notre interface utilisateur flexible, vous pouvez 
                                                personnaliser et ajuster votre site selon vos besoins spécifiques. 
                                                Que vous ayez des exigences uniques ou des préférences particulières, 
                                                notre système s'adapte facilement pour répondre à vos attentes.

                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>
  )
}

export default Process