// src/routes/AppRoutes.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Login from './components/screens/auth/login';
import Dashboard from './components/screens/admin/dashboard';
import Project from './components/screens/Project';

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/service" element={<Home />} />
      <Route path="/contact" element={<Home />} />
      <Route path="/about" element={<Home />} />
      <Route path="/store" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/dashboard/projects" element={<Dashboard />} />
      <Route path="/project/:id" element={<Project/>} />
      <Route path="/dashboard/project/new" element={<Dashboard />} />
      <Route path="*" element={<Home />} /> {/* Default route */}
    </Routes>
  );
}

export default AppRoutes;
