// carousel.jsx
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css'; 
import './carousel.css';


const Carousel = ({ children }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    responsive: [
        {
          breakpoint: 1120, 
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        }, {
            breakpoint: 850, 
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
        {
          breakpoint: 600, 
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
  };

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        {children}
      </Slider>
    </div>
  );
};

export default Carousel;
