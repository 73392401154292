import React from 'react'
import { useTranslation } from 'react-i18next';

function Features() {

  const { t } = useTranslation("global");

  return (
    <div id="about" className="feature-style-five-area default-padding-top custom_features pb-4">
          <div className="bg_bk"></div>
            <div className="container">
              <div className="row align-center">
                  <div className="feature-style-five col-lg-5">
                      <h4 className='tag'>{t('features.label')}</h4>
                      <h2>{t("features.title")}</h2>
                      <p>
                          {t("features.body")}
                      </p>
                      <div className="feature-fact mt-35">
                          <div className="fun-fact">
                              <div className="counter">
                                  <div className="timer" data-to="1" data-speed="2000">1</div>
                                  <div className="operator">K</div>
                              </div>
                              <span className="medium">{t("features.customers")}</span>
                          </div>
                          <div className="fun-fact">
                              <div className="counter">
                                  <div className="timer" data-to="98" data-speed="2000">98</div>
                                  <div className="operator">+</div>
                              </div>
                              <span className="medium">{t("features.feedback")}</span>
                          </div>
                      </div>
                  </div>
                  <div className="feature-style-five col-lg-7 pl-50 pl-md-15 pl-xs-15">
                      <div className="infinite-updown-illustration">
                          <img src="assets/img/shape/37.png" alt="illustration" />
                      </div>
                      <div className="row">

                          <div className="feature-style-five-grid col-12 col-md-6">
                            <div className="item border border-grey shadow-none">
                              <i className="far fa-monitor-heart-rate"></i>
                              <h4>{t("features.section1.title")}</h4>
                              <p>
                                {t("features.section1.subtitle")}
                              </p>
                            </div>
                            <div className="item border border-grey shadow-none">
                              <i className="fas fa-chart-pie"></i>
                              <h4>{t("features.section2.title")}</h4>
                              <p>
                                {t("features.section2.subtitle")}
                              </p>
                            </div>
                          </div>

                          <div className="feature-style-five-grid col-12 col-md-6">
                            <div className="item border border-grey shadow-none">
                              <i className="fas fa-comments-alt"></i>
                              <h4>{t("features.section3.title")}</h4>
                              <p>
                                  {t("features.section3.subtitle")}
                              </p>
                            </div>
                            <div className="item border border-grey shadow-none">
                              <i className="fas fa-rocket"></i>
                              <h4>{t("features.section4.title")}</h4>
                              <p>
                                  {t("features.section4.subtitle")}
                              </p>
                            </div>
                          </div>

                      </div>
                  </div>
              </div>
            </div>
    </div>
  )
}

export default Features