import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import imgs from '../constants/images';
import { firestore, collection, getDocs } from './admin/logic/firebase';
import { Link, useNavigate } from 'react-router-dom';

function Projects({theme}) {

    const { t } = useTranslation("global");
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchProjects = async () => {
        try {
            const querySnapshot = await getDocs(collection(firestore, 'projects'));
            const projectsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setProjects(projectsData);
        } catch (error) {
            console.error('Error fetching projects:', error);
        } finally {
            setLoading(false);
        }
        };

        fetchProjects();
    }, []);
    const navigate = useNavigate();
    const handleNavigate = (id) => {
        navigate(`/project/${id}`);
      };

    const ModelCard = ({ id,name, link, description, photo,fileUrl }) => (
        <div className="col-12 col-lg-6">
            <div onClick={() => handleNavigate(id)} className="single-item card_project">
                <div className="item text-center ">
                    <div className="thumb">
                        <div className="img">
                            <img src={photo} alt={name} />
                        </div>
                    </div>
                    <div className="devider"></div>
                        <div className="info">
                        <div className="content">
                            <h4>{name}</h4>
                            
                            <p>{  description.length > 70 ? description.slice(0, 70) + ' . . . ' : description}</p>

                            <div className="main">
                                <div className="footer">
                                    <img src={imgs.favicon} alt="" />
                                    <div>
                                        <span> Ndalos-Soft </span>
                                        {/* <div className='timing'>
                                            <i className="fa fa-calendar"></i>
                                            <small> Il a 1 an </small>
                                        </div> */}
                                    </div>
                                </div>
                                <div>{fileUrl !== "" ? (
                                        <a href={fileUrl} className='relative video-btn'> <b className="text-dark"> <i className="action fa fa-download mx-3"></i></b> </a>
                                    ) : link !== "" ? <>
                                        <a target="_blank" rel="noreferrer" href={link} className='relative video-btn'> <b className="text-dark"> <i className="action fa fa-link mx-3"></i></b>  </a>
                                    </> : <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

  return (
    <div id="project" class={`team-area default-padding bottom-less custom_project ${theme === 'dark' ? 'bg-light' : 'bgdark'}`}>
        <div className="bg_bk"></div>

        <div className="col-lg-8 offset-lg-2 content">
            <div className="site-heading text-center">
                <h2>{t("project.title")}</h2>
                <div className="devider"></div>
                <p>
                    {t('project.subtitle')}
                </p>
            </div>
        </div>

        <div className="container">
            <div className="team-items">
                <div className="row">
                    {projects.map(member => (
                        <ModelCard
                            id={member.id}
                            key={member.id}
                            name={member.title}
                            link={member.url}
                            description={member.description}
                            photo={member.imageUrl} 
                            fileUrl={member.fileUrl}
                        />
                    ))}
                </div>

            </div>
        </div>
    </div>
  )
}

export default Projects