// // import './App.css';
// import Header, { Header } from './components/header';
// import Bannier from './components/bannier';
// import Footer from './components/footer';
// import { ThemeContext } from './logic/theme/themeProvider';
// import { useContext } from 'react';
// import Features from './components/features';
// import About from './components/about';
// import Process from './components/process';
// import Team from './components/team';
// import OurServices from './components/ourservices';
// import Parteners from './components/parteners';
// import Projects from './components/projects';
// function App() {
//   const { theme, toggleTheme } = useContext(ThemeContext);

//   return (
//     <>
//       <Header />
//       <Bannier theme={theme} />
//       <Features  />
//       <About theme={theme}/>
//       {/* <Process /> */}
//       <OurServices theme={theme} />
//       <Team theme={theme} />
//       <Parteners />
//       <Projects theme={theme} />
//     <Footer theme={theme} />
      
//     </>
//   );
// }

// export default App;

import React, { useContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeContext } from './logic/theme/themeProvider';
import AppRoutes from './AppRouter';

function App() {
  const { theme } = useContext(ThemeContext);

  return (
    <Router>
      <AppRoutes theme={theme} />
    </Router>
  );
}

export default App;
